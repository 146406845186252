<template>
  <div class="doc-wrapper">
    <p class="doc-title__text">{{$t('docs.title')}}</p>
    <div class="doc-content__wrap">
      <sidebar />
      <div ref="routeWrap" class="help-route-view__wrap">
        <router-view />
        <app-footer></app-footer>
      </div>
    </div>
    <backTo-top isContainer=".help-route-view__wrap"></backTo-top>
  </div>
</template>

<script setup>
import { onUpdated, ref } from 'vue'
import sidebar from './sidebar.vue'
import AppFooter from 'components/app/app-footer'

const routeWrap = ref()

onUpdated(() => {
  // 解决切换页面，滚动条不回到顶点bug
  if (routeWrap.value) {
    routeWrap.value.scrollTop = 0
  }
})

</script>
<style lang="scss" scoped>
@import '@/styles/common.scss';
@import './style.scss';
.doc-wrapper {
  height: calc(100% - 20px);
  box-sizing: border-box;
  padding: 0 0 0 24px;
  .doc-title__text {
    font-size: 18px;
    margin: 15px 0;
    line-height: 20px;
    font-weight: 500;
  }
  .doc-content__wrap {
    display: flex;
    .help-route-view__wrap {
      :deep(.wrapper) {
        padding: 87px 83px 24px;
      }
      flex: 1;
      // padding: 0 83px;
      height: calc(100vh - 108px);
      overflow-y: auto;
    }
  }
}
</style>
