<template>
  <div class="sidebar-wrapper" :class="{isEn: !$isZh()}">
    <el-menu
      :default-active="defaultActive"
      active-text-color="#ffffff"
      :background-color="$isDark() ? '#10121b' : '#f2f4f5'"
      text-color="#B8BBCC"
      router
    >
      <!-- <el-menu-item index="introduction">
        <svg-icon name="yun" />
        <span class="item-title__text">平台简介</span>
      </el-menu-item> -->
      <el-sub-menu index="platform">
        <template #title>
          <svg-icon name="yun" />
          <span class="item-title__text">{{$t('docs.platform')}}</span>
        </template>
        <el-menu-item index="/help/docs/introduction">{{$t('docs.introduction')}}</el-menu-item>
      </el-sub-menu>

      <el-sub-menu index="search">
        <template #title>
          <svg-icon name="gaojisousuo" />
          <span class="item-title__text">高级检索说明</span>
        </template>
        <el-menu-item index="/help/docs/search/field">高级检索字段说明</el-menu-item>
        <el-menu-item index="/help/docs/search/syntax">高级检索语法说明</el-menu-item>
      </el-sub-menu>

      <el-sub-menu index="api">
        <template #title>
          <svg-icon name="API" />
          <span class="item-title__text">{{$t('docs.api')}}</span>
        </template>
        <el-menu-item index="/help/docs/api/introduction">{{$t('docs.api_explain')}}</el-menu-item>
        <el-menu-item index="/help/docs/api/userlimit">{{$t('docs.api_uselimit')}}</el-menu-item>
        <el-sub-menu index="api-interface">
          <template #title>{{$t('docs.api_list')}}</template>
          <el-menu-item index="/help/docs/api/list/submittask">
            <template #title>
              <span class="tag post-tag">POST</span>
              {{$t('docs.submit')}}
            </template>
          </el-menu-item>
          <el-menu-item index="/help/docs/api/list/searchtask">
            <template #title>
              <span class="tag get-tag">GET</span>
              {{$t('docs.search')}}
            </template>
          </el-menu-item>
          <el-menu-item index="/help/docs/api/list/getsubmitlist">
            <template #title>
              <span class="tag get-tag">GET</span>
              {{$t('docs.get_submitlist')}}
            </template>
          </el-menu-item>
          <el-menu-item index="/help/docs/api/list/gettaskstatus">
            <template #title>
              <span class="tag get-tag">GET</span>
              {{$t('docs.search_taskstatus')}}
            </template>
          </el-menu-item>
          <el-menu-item index="/help/docs/api/list/gettaskoverview">
            <template #title>
              <span class="tag get-tag">GET</span>
              {{$t('docs.get_taskoverview')}}
            </template>
          </el-menu-item>
        </el-sub-menu>
      </el-sub-menu>
      <el-menu-item index="/help/docs/terms">
        <svg-icon name="fuwu" />
        <span class="item-title__text">{{$t('docs.terms')}}</span>
      </el-menu-item>
      <el-menu-item index="/help/docs/privacy">
        <svg-icon name="yingsi" />
        <span class="item-title__text">{{$t('docs.privacy')}}</span>
      </el-menu-item>
      <el-menu-item index="/help/docs/faq">
        <svg-icon name="wenti" />
        <span class="item-title__text">{{$t('docs.faq')}}</span>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute()
const defaultActive = ref()

onMounted(() => {
  defaultActive.value = route.name
})
</script>
<style lang="scss" scoped>
@import '@/styles/common.scss';
.sidebar-wrapper {
  width: 245px;
  height: calc(100vh - 106px);
  overflow-y: auto;
  overflow-x: hidden;
  &.isEn {
    width:fit-content;
    min-width: 245px;
  }
  .item-title__text {
    padding-left: 4px;
  }
  .tag {
    box-sizing: border-box;
    // display: inline;
    height: 16px;
    line-height: 14px;
    margin-right: 3px;
    margin-top: 12px;
    float: left;
    font-size: 12px;
    border-radius: 2px;
    padding: 1px 2px;
    width: 38px;
    text-align: center;
  }
  .post-tag {
    color: #4aa8ff;
    border: 1px solid #164c7e;
    background: #112a45;
  }
  .get-tag {
    color: #4bac1d;
    background: #111f1c;
    border: 1px solid #0e4133;
  }
  :deep(.is-active) {
    background-color: #202330 !important;
    // color: var(--color-text-1) !important;
    &.el-menu-item {
      border-right: 2px solid var(--color-priamry);
      color: var(--color-text-1) !important;
      // font-weight: 600;
      // &:hover {
      //   width: 100% !important;
      // }
      &>.item-title__text {
        color: var(--color-text-1) !important;
      }
      .svg-icon {
        color: var(--color-text-1);
      }
    }
  }
  :deep(.svg-icon) {
    color: var(--color-text-3);
    font-size: 16px;
  }
  :deep([role=menubar]) {
    border-right: 2px solid var(--color-border-2);
    min-height: 100%;
    line-height: 40px;
    &>.el-menu-item {
      color: var(--color-text-2);
      padding-left: 10px !important;
    }
    .item-title__text {
      color: var(--color-text-2);
    }
    &>.el-sub-menu {
      &>.el-sub-menu__title {
        color: var(--color-text-2);
        padding-left: 10px !important;
      }
      &>.el-menu {
        &>.el-menu-item {
          color: var(--color-text-2);
          padding-left: 30px !important;
        }
        .el-sub-menu__title {
          color: var(--color-text-2);
          padding-left: 30px !important;
        }
        .el-menu {
          &>.el-menu-item {
            color: var(--color-text-2);
            padding-left: 30px !important;
            display: block;
            line-height: 40px;
          }
        }
      }
    }
  }
  :deep(.el-menu-item) {
    line-height: 40px;
    height: 40px !important;
    display: block;
    &:not(.is-active) {
      &:hover {
        background-color: var(--detect-config-bg);
        // border-right: 2px solid transparent;
        width: calc(100% - 0.5px);
      }
    }
  }
  :deep(.el-sub-menu__title) {
    height: 40px !important;
  }
}

[data-theme='light'] {
  .sidebar-wrapper {
    .post-tag {
      color: #4aa8ff;
      border: 1px solid #e8eaed;
      background: #e8eaed;
    }
    .get-tag {
      color: #4bac1d;
      background: #e8eaed;
      border: 1px solid #e8eaed;
    }
    :deep(.is-active) {
      background-color: #ffffff !important;
    }
    :deep(.el-sub-menu__title) {
      color: var(--color-text-2) !important;
    }
    :deep(.el-icon) {
      color: #242933 !important;
    }
  }
}
</style>
